class NavigationOverlay {
    constructor(trigger_element_selector, 
                overlay_element_selector, 
                mode = "unique",
                nav_selector = "nav.dynamic",
                nac_placeholder_selector = ".nav-placeholder",
                group = "overlays") {

        this.trigger_element_selector = trigger_element_selector;
        this.overlay_element_selector = overlay_element_selector;
        this.nav_selector = nav_selector;
        this.nav_placeholder_selector = nac_placeholder_selector;
        this.group = group;
        this.mode = mode;

        this.onOpenCallback = null;

        $(this.overlay_element_selector).addClass("navigation-overlay");

        let that = this;

        if (this.trigger_element_selector !== false) {
            $("body").off("click", this.trigger_element_selector);
            $("body").on("click", this.trigger_element_selector, function() {
                that.toggle();
            });
        }
    }

    equals(other) {
        if (other instanceof NavigationOverlay) {
            return other.overlay_element_selector == this.overlay_element_selector;
        }
        return false;
    }

    getScrollTopValue() {
        let scrollTop = $(this.overlay_element_selector).data("pre-open-scroll")
        if (scrollTop !== undefined) {
            return scrollTop;
        }
        return false;
    }

    setScrollTopValue(value) {
        $(this.overlay_element_selector).data("pre-open-scroll", value);
    }

    isVisible() {
        return $(this.overlay_element_selector).hasClass("visible");
    }

    isActive() {
        return $(this.overlay_element_selector).hasClass("active");
    }

    open(animate = true, callback = false) {
        this.group_stack_push();
        $("body, html").addClass("noscroll");
        $(this.overlay_element_selector).addClass("active");
        $(this.overlay_element_selector).outerHeight();
        this.show(animate, callback);
    }

    close(animate = true, callback = false) {
        this.group_stack_pop();
        let that = this;
        this.hide(animate, function() {
            $(that.overlay_element_selector).removeClass("active");
            $(that.overlay_element_selector).outerHeight();
            
            if (that.group_stack_is_empty()) {
                $("body, html").removeClass("noscroll");
                console.log(that.getScrollTopValue());
                $("html").animate({scrollTop: that.getScrollTopValue() + "px"}, 100);
            }
            if (callback !== false) {
                callback();
            }
        });
    }

    show(animate = true, callback = false) {
        let animation_speed = $(this.overlay_element_selector).css("--animation-speed-ms");
        if (this.isVisible()) {
            animate = false;
        }
        $(this.overlay_element_selector).addClass("visible");
        if (animate) {
            setTimeout(function() {                    
                if (callback !== false) {
                    callback();
                }
                if (this.onOpenCallback !== null) {
                    this.onOpenCallback();
                }
            }.bind(this), animation_speed);
        } else {              
            if (callback !== false) {
                callback();
            }
            if (this.onOpenCallback !== null) {
                this.onOpenCallback();
            }
        }
    }

    hide(animate = true, callback = false) {
        let animation_speed = $(this.overlay_element_selector).css("--animation-speed-ms");
        if (!this.isVisible()) {
            animate = false;
        }
        $(this.overlay_element_selector).removeClass("visible");
        if (callback !== false) {
            if (animate) {
                setTimeout(callback, animation_speed);
            } else {                
                callback();
            }
        }
    }

    activate() {        
        if (this.is_already_in_stack()) {
            if (!this.is_on_top_of_stack()) {
                let other = this.get_stack_top();
                let that = this;
                other.close(true, function() {
                    that.activate();
                });
                return;
            } else {
                this.show();
            }
        } else {
            let top = this.get_stack_top();
            let that = this;
            if (top !== false) {
                this.setScrollTopValue(top.getScrollTopValue());
                if (this.mode == "unique") {
                    this.group_stack_pop();
                }
                top.hide(true, function() {
                    that.open();
                    if (that.mode == "unique") {
                        $(top.overlay_element_selector).removeClass("active");
                    }
                });
            } else {
                this.open();
            }
        }
    }

    deactivate(callback = false) {        
        let that = this;
        this.close(true, function() {
            if (that.get_group_stack_size() > 0) {
                that.get_stack_top().show(true, callback);
            } else if (callback !== false) {
                callback();
            }
        });
    }

    get_group_stack() {
        if (!NavigationOverlay.group_stacks.has(this.group)) {
            NavigationOverlay.group_stacks.set(this.group, []);
        }
        return NavigationOverlay.group_stacks.get(this.group);
    }

    group_stack_push() {
        this.get_group_stack().push(this);
    }

    group_stack_pop() {
        return this.get_group_stack().pop();
    }

    get_group_stack_size() {
        return this.get_group_stack().length;
    }

    get_stack_top() {
        let size = this.get_group_stack_size();
        if (size > 0) {
            return this.get_group_stack()[size - 1];
        }
        return false;
    }

    group_stack_is_empty() {
        return this.get_group_stack_size() === 0;
    }

    is_on_top_of_stack() {
        let stack_top = this.get_stack_top();
        if (stack_top === false) {
            return false;
        }
        return this.equals(stack_top);
    }

    is_already_in_stack() {
        for (let overlay of this.get_group_stack()) {
            if (this.equals(overlay)) {
                return true;
            }
        }
        return false;
    }

    toggle() {
        let is_visible = this.isVisible();
        let that = this;

        if (that.trigger_element_selector !== false && $(that.trigger_element_selector).parents(that.nav_selector).length > 0) {
            if (!is_visible) {
                let top = $(that.nav_selector).height();
                $(that.overlay_element_selector).css("top", top - 2 + "px");
                this.setScrollTopValue($("html").scrollTop());
                // Potentially animate scroll
                if (!$(that.nav_selector).hasClass("fixed")) {
                    let nav_trigger_point = $(that.nav_placeholder_selector).offset().top + 2;
                    $("html, body").stop(true, false).animate({scrollTop: nav_trigger_point}, 100, "swing", function() {
                        that.activate();
                        $(that.nav_selector).addClass("fixed");
                    });
                } else {
                    that.activate();
                }
            } else {
                this.deactivate();
            }
        } else {
            if (is_visible) {
                this.deactivate();
                $(that.overlay_element_selector).removeClass("fixed");
            } else {
                this.setScrollTopValue($("html").scrollTop());
                this.activate();
                $(that.overlay_element_selector).addClass("fixed");
            }
        }           
    };
}

NavigationOverlay.group_stacks = new Map();

$(function() {
	$("body").on("click", ".nightmode-toggler", function() {
		let body = $("body");
		body.toggleClass("nightmode");
		// if (cookiesAllowed()) {
		Cookies.set("nightmode", body.hasClass("nightmode") ? "true" : "false", {expires: 14});
		//}
	});

});

$("body").on("click", ".pi_image_open", function(e) {
    return;
    
    var preview = new Dialog();
    var close = function() {
        preview.close();
    }

    var medium = $(this).attr("medium");    

    preview.build()
        .addTitle("Bild-Anzeige")
        .addHtml(`<div style="text-align: center"><img align="center" class="dialog_picture" src="${medium}" /></div>`)
        .addButton("Ok", "cancel", close)
        .onEscape(close)
        .onEnter(close)
        .show();

    e.preventDefault();
});

function materialFullscreenInitPictures() {
    /*
	$(".pi_image_open:not(.pi_materialboxed)").materialbox({
        onOpenStart: function(el) {
            var width = $(el).css("width");
            var maxwidth = $(el).css("max-width");
            console.log(el);
            console.log(maxwidth);
            $(el).attr("tmpwidth", width);
            $(el).attr("tmpmaxwidth", maxwidth);
            $(el).css({"max-width": "unset !important"});
            $(el).attr("src", $(el).attr("large"));
        },
        onCloseEnd: function(el) {
            var width = $(el).attr("tmpwidth");
            var maxwidth = $(el).attr("tmpmaxwidth");
            $(el).css("width", width);
            $(el).css("max-width", maxwidth);
        }
    });
	$(".pi_image_open:not(.pi_materialboxed)").addClass("pi_materialboxed");*/
}

$(function() {
    $('a.lightbox-picture').simpleLightbox({
        sourceAttr: "data-src",
        nav: true,
        captions: false,
        fileExt: "png|jpg|jpeg|gif|svg"
    });
});

(function ( $ ) {
    $.fn.gallerySlider = function() {
        this.each(function() {
            var type = "large";
            var pretype = $(this).parents(".gallery").attr("type");
            if (pretype == "slider_small") {
                type = "small";                
            }
            
            $(this).data("gallerySlider-type", type);
            if ($(this).data("gallerySlider") == "active") {
                if ($(this).data("gallerySlider-size") == "large") {
                    $(this).galleryEnlarge();
                } else {
                    $(this).galleryShrink();
                }
                return;
            }
            $(this).data("gallerySlider", "active");
            $(this).addClass("gallerySlider");
            $(this).carousel({
                fullWidth: true,
                indicators: true
            });
            $(this).find("img").css({"max-width": "100%", "max-height": "100%"});
            $(this).find(".carousel-item").css({"min-height": "100px"});
            $(this).galleryEnlarge();
        });
    };
    
    $.fn.galleryEnlarge = function() {
        this.each(function() {
            var card = $(this).parents(".gallery");
            
            
            var carousel = $(this).parents(".gallerySlider").addBack();
            
            var height = $(carousel).data("gallerySlider-type") == "large" ? "700px" : "300px";
            
            $(carousel).data("gallerySlider-size", "large");
            var active = $(carousel).find(".carousel-item.active");
            var activeNum = $(carousel).find(".carousel-item").index(active);
            $(carousel).css("max-width", "100%");
            $(carousel).css("max-height", height);
            $(carousel).css("height", height);            
            $(carousel).find(".carousel-slider").carousel({
                fullWidth: true,
                indicators: true
            });
            $(carousel).find(".carousel-slider").carousel("set", activeNum);
        });
    }
    
    
    $.fn.galleryShrink = function() {
        this.each(function() {
            var carousel = $(this).parents(".gallerySlider").addBack();
            
            var height = $(carousel).data("gallerySlider-type") == "large" ? "500px" : "250px";
            
            $(carousel).data("gallerySlider-size", "small");
            var active = $(carousel).find(".carousel-item.active");
            var activeNum = $(carousel).find(".carousel-item").index(active);
            $(carousel).css("max-width", "600px");
            $(carousel).css("max-height", height);
            $(carousel).css("height", height);            
            $(carousel).find(".carousel-slider").carousel({
                fullWidth: true,
                indicators: true
            });
            $(carousel).find(".carousel-slider").carousel("set", activeNum);
        });
    }
}(jQuery));


// LARGE SLIDER: ZOOM & SHRINK
$("body").on("click", ".gallery_enlarge", function() {    
    $(this).hide();
    $(this).parent().find(".gallery_shrink").show();
    $(this).parents(".gallery").find(".gallerySlider").galleryEnlarge();
});

$("body").on("click", ".gallery_shrink", function() {
    $(this).hide();
    $(this).parent().find(".gallery_enlarge").show();
    $(this).parents(".gallery").find(".gallerySlider").galleryShrink();
});

// LARGE & SMALL SLIDER: NEXT & PREV
$("body").on("click", ".gallery_next", function() {
    var card = $(this).parent().parent().parent().parent();
    $(card).find(".carousel.carousel-slider").carousel("next");
});

$("body").on("click", ".gallery_prev", function() {
    var card = $(this).parent().parent().parent().parent();
    $(card).find(".carousel.carousel-slider").carousel("prev");
});
let nav_placeholder = document.querySelector(".nav-placeholder > .navbar");
let nav_main = document.querySelector("nav.dynamic");

let nav_main_jq = $("nav");
let nav_placeholder_jq = $(".nav-placeholder");
let nav_wrapper_jq = $(".nav-wrapper");
let main_jq = $("main");

let sticky_observer = new IntersectionObserver(
    function(elements) {
        let e = elements[0];
        nav_main.classList.toggle("fixed", e.intersectionRatio < 1);        
        nav_main.classList.toggle("smart-scroll", e.intersectionRatio < 1);        
    },
    {threshold: [1]}
);
sticky_observer.observe(nav_placeholder);

let hidden_observer = new IntersectionObserver(
    function(elements) {
        for (let e of elements) {
            e.target.classList.toggle("outscrolled", e.intersectionRatio <= 0);
        }
    },
    {threshold: [1]}
);
hidden_observer.observe(nav_main);

window.addEventListener("DOMContentLoaded", function() {
    let previous_scroll = 0;
    let previous_trigger = 0;
    let trigger_distance = 10;
    let last_scroll_was_up = false;
    let hide_border_y = main_jq.offset().top; //nav_placeholder_jq.offset().top + nav_placeholder_jq.outerHeight();
    $(window).scroll(function() {
        let current_scroll = $(this).scrollTop();
        if (Math.abs(current_scroll - previous_trigger) > trigger_distance) {
            if (nav_main_jq.hasClass("smart-scroll")) {         
                if (current_scroll > previous_scroll && current_scroll > hide_border_y) {
                    nav_main_jq.addClass("smart-scroll-hidden");
                } else {                
                    nav_main_jq.removeClass("smart-scroll-hidden");
                }
            }
            previous_trigger = current_scroll;
        }
        last_scroll_was_up = current_scroll < previous_scroll;
        previous_scroll = current_scroll;        
    })
});

let hover_leave_timer;

function navigation_hover_off() {
    hover_leave_timer = setTimeout(function() {
        $(".nav-sub-menu-floating").removeClass("visible");
    }, 10);
}

window.addEventListener("DOMContentLoaded", function() {
    $(".nav-entry-level-1").hover(function() {
        if ($(this).parents(".mobile").length) {
            return;
        }
        clearTimeout(hover_leave_timer);
        if ($(this).find(".nav-entry-children").length) {
            children_block = $(this).find(".nav-entry-children");
            let height = $(this).height();
            let top = $(this).offset().top + height - 1;
            let width = $(this).width();
            let left = $(this).offset().left; // + width / 2;
            $(".nav-sub-menu-floating").addClass("visible");
            $(".nav-sub-menu-floating").css("top", top + "px");
            $(".nav-sub-menu-floating").css("left", left + "px");
            $(".nav-sub-menu-floating").css("min-width", width + "px");
            $(".nav-sub-menu-floating").html($(children_block).html());            
        } else {
            $(".nav-sub-menu-floating").removeClass("visible");
        }
    }, function() {
        navigation_hover_off();
    });  

    $(".nav-sub-menu-floating").hover(function() {
        clearTimeout(hover_leave_timer);
    }, function() {
        navigation_hover_off();
    });
});

window.addEventListener("DOMContentLoaded", function() {
    let menu_toggle_overlay = new NavigationOverlay(".menu-toggler", "nav.mobile");
})


/*
$("body").on("click", ".menu-toggler", function() {
    let is_visible = $("nav.mobile").hasClass("visible");

    if ($(this).parents("nav.dynamic").length > 0) {
        if (!is_visible) {
            let top = $("nav.dynamic").height();
            $("nav.mobile").css("top", top + "px");
            $("nav.mobile").data("pre-open-scroll", $("html").scrollTop());
        }
        let nav = $(this).parents("nav.dynamic");
        if (is_visible) {
            // Hide
            $("body, html").removeClass("noscroll");
            $("nav.mobile").css({opacity: 0});
            setTimeout(function() {
                $("nav.mobile").removeClass("visible");
                let scrollTop = $("nav.mobile").data("pre-open-scroll");
                $("html").animate({scrollTop: $("nav.mobile").data("pre-open-scroll") + "px"}, 100);
            }, 200);
        } else {
            // Show
            if ($(nav).hasClass("fixed")) {
                // Show cirectly
                $("body, html").addClass("noscroll");
                $("nav.mobile").addClass("visible").show();
                $("nav.mobile").css({opacity: 1});
            } else {
                // Animate transition
                let nav_trigger_point = $(".nav-placeholder").offset().top + 1;
                $("html").animate({scrollTop: nav_trigger_point + "px"}, 100, "swing", function() {
                    $("body, html").addClass("noscroll");
                    $("nav.mobile").addClass("visible").show();  
                    $("nav.mobile").css({opacity: 1});
                });
            }
        }
    } else {
        $("nav.mobile").toggleClass("visible");
        $("nav.mobile").toggleClass("fixed");
    }
});

*/
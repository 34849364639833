
$(function() {
    let search_toggle_overlay = new NavigationOverlay(".search-trigger", ".search-overlay");
    search_toggle_overlay.onOpenCallback = function() {
        $("#search-input").focus();
    }

    $(".search-overlay").html(`
        <div class="default-div">
            <div class="search-input">
                <input type="text" id="search-input" placeholder="Suchen Sie auf unserer Webseite" />
            </div>
            <div class="search-results" id="search-results"></div>
        </div>
    `);

    let searchApi = new SearchInput("#search-input", "#search-results");
});
class PictureSlideshow {
    constructor(container, config) {
        this.container = container;
        this.config = config;
        this.slides = [];
        this.secondarySlides = false;
        this.secondaryClass = "";
        let that = this;
        $(this.container).find(".slide-show-image").each(function() {
            that.slides.push($(this));
            $(this).addClass("slide-show-inactive");
        });

        this.syncWithContainer = config.syncWithContainer || false;
        if (this.syncWithContainer === false) {
            let syncWithData = $(this.container).data("syncWithContainer");
            this.secondaryClass = $(this.container).data("secondaryClass");
            if (this.secondaryClass === undefined) {
                this.secondaryClass = "";
            }
            if (syncWithData !== undefined) {
                this.syncWithContainer = syncWithData;
            }
        }
        
        if (this.syncWithContainer !== false) {
            // Setup sync container
            this.secondarySlides = [];
            this.syncWithContainer = $(this.syncWithContainer);
            this.setupSyncContainer();
        }

        let numSlides = this.slides.length;
        let startSlide = Math.floor(Math.random() * (numSlides - 1));
        this.currentSlide = startSlide;
        this.interval = false;
        this.changeSpeedSeconds = 10;

        this.clickable = true;

        if (this.clickable) {
            $(this.container).on("click", function() {
                if (that.isActive()) {
                    that.stop();
                    that.nextSlide(); 
                    that.start();
                } else {
                    that.nextSlide();
                }
            });
        }

        this.showImageId(this.currentSlide);
        this.start();
    }

    isActive() {
        return this.interval !== false;
    }

    showImageId(imageId) {
        let image = this.slides[imageId];
        this.showImage(image);
        if (this.secondarySlides !== false) {
            let secondaryImage = this.secondarySlides[imageId];
            this.showImage(secondaryImage);
        }
    }

    hideImageId(imageId) {
        let image = this.slides[imageId];
        this.hideImage(image);
        if (this.secondarySlides !== false) {
            let secondaryImage = this.secondarySlides[imageId];
            this.hideImage(secondaryImage);
        }
    }

    showImage(image) {
        image.removeClass("slide-show-inactive");
        image.addClass("slide-show-active");
        image.addClass("slide-show-on-top");
    }

    hideImage(image) {
        image.addClass("slide-show-inactive");
        image.removeClass("slide-show-active");
        image.removeClass("slide-show-on-top");
    }

    nextSlide() {
        this.hideImageId(this.currentSlide);
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
        this.showImageId(this.currentSlide);
    }

    stop() {
        if (this.interval !== false) {
            clearInterval(this.interval);
            this.interval = false;
        }
    }

    start(changeSpeedSeconds = false) {
        this.stop();
        if (changeSpeedSeconds === false) {
            changeSpeedSeconds = this.changeSpeedSeconds;
        } else {
            this.changeSpeedSeconds = changeSpeedSeconds;
        }
        this.interval = setInterval(this.nextSlide.bind(this), changeSpeedSeconds * 1000);
    }

    setupSyncContainer() {        
        $(this.syncWithContainer).prepend(`
            <div class="slide-show slide-show-synced ${this.secondaryClass}">

            </div>
        `);
        this.syncWithContainer = $(this.syncWithContainer).find(".slide-show-synced");
        // Find the corresponding image for each original image
        for (let image of this.slides) {
            let secondaryImageUrl = image.find("img").data("secondarySrc");
            let secondaryImage = $(`
                <div class="slide-show-image slide-show-inactive slide-show-image-secondary">
                    <img src="${secondaryImageUrl}" />
                </div>
            `);
            this.syncWithContainer.append(secondaryImage);
            this.secondarySlides.push(secondaryImage);
        }
    }
}
function cookiesAllowed() {
  return Cookies.get("cookies-accepted") == "true";
}


$(function() {
    if ($("body").hasClass("no-cookie-query")) {
      return;
    }
    if (Cookies.get("cookies-accepted") != "true" && Cookies.get("cookies-accepted") != "rejected") {
        let favicon_url = document.querySelector('link[rel="shortcut icon"]').href;
        $("body").append(`
            <div class="cookie-overlay">
              <div id="cookie-modal" class="modal bottom-sheet">
                <div class="modal-content" style="color: rgba(0, 0, 0, 0.87);">                
                  <div class="cookie-title">
                    <img src="${favicon_url}" style="max-width: 80px" />
                    <h4>Cookie-Hinweis</h4>
                  </div>
                  <p>
                    Diese Webseite verwendet Cookies. <br />
                    Für die korrekte Funktionsweise der Webseite sind Cookies erforderlich.<br />
                    Für weitere Informationen hierzu lesen Sie unsere <a href="datenschutz">Datenschutzinfos</a>.
                  </p>
                </div>
                <div class="cookie-buttons">
                  <div id="cookies-accept" class="button">Alle akzeptieren</div>
                  <div id="cookies-reject" class="button">Erforderliche</div>
                  <div id="cookies-cancel" class="button">Schließen</div>
                </div>
              </div>
            </div>
        `);
        
        $("body").on("click", "#cookies-accept", function() {
            Cookies.set("cookies-accepted", "true", {expires: 14});
            $(".cookie-overlay").remove();
        });
        
        $("body").on("click", "#cookies-reject", function() {
            Cookies.set("cookies-accepted", "rejected", {expires: 14});
            $(".cookie-overlay").remove();
        });
        $("body").on("click", "#cookies-cancel", function() {
            $(".cookie-overlay").remove();
        });
    } else {        
        // Refresh
        Cookies.set("cookies-accepted", "true", {expires: 14});
    }
});
function openWindowWithPost(url, data) {
    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

$("body").on("click", ".document_download", function() {
    var doc = $(this).attr("document");
    var haspw = $(this).attr("haspw") == "true";

    var path = "document/get/" + doc;

    if (haspw) {
        var dlg = new Dialog();
        dlg.input("Passwort angeben", 
        "Dieses Dokument ist durch ein Passwort geschützt. Bitte geben Sie das Passwort ein, um das Dokument herunterzuladen.",
        "Passwort",
        "",
        "Passwort",
        "Ok",
        "Abbrechen",
        function(input) {
            dlg.close();
            if (input != false && input != "") {
                openWindowWithPost(path, {
                    pw: input
                });
            }
        });
    } else {
        var win = window.open(path, "_blank");
        win.focus();
    }
});
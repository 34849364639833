class WordChanger {
    constructor(container, config = {}) {
        this.container = container;
        this.config = config;
        this.current_word = 0;
        this.words = [];
        let that = this;
        this.delay = 300; 
        this.speed = 80;
        this.container.find(".changeable-word").each(function() {
            that.splitWordIntoLetters($(this));
            that.words.push($(this));
        });
        this.current_word = this.words.length - 1;
        this.interval = false;
        this.alignment = config["align"] || "auto";

        let start_delay_letters = config["startDelayLetters"] || 0;
        let start_delay = this.speed * start_delay_letters;
        let change_interval = config["interval"];

        this.updateLayout();
        $(window).on("resize", function() {
            that.updateLayout();
        });

        setTimeout(function() {
            that.updateLayout();
            that.changeWord();
            if (change_interval !== undefined) {
                that.start(change_interval);
            }
        }, start_delay);
    }

    updateLayout() {
        // Update width
        let width = 0;
        for (let word of this.words) {
            width = Math.max(word.outerWidth(), width);
        }
        $(this.container).css("width", (width + 10) + "px");
        // Update layout
        switch (this.alignment) {
            case "left":
            case "center":
            case "right":
                $(this.container).attr("class", "changeable-words " + this.alignment);
                break;
            case "auto":
                // Detect if we are the first, last or some element in the middle in this line
                let prev = $(this.container).prev();
                let next = $(this.container).next();
                let tolerance = 5;
                let first_in_line = true;
                let last_in_line = true;
                let this_top = $(this.container).offset().top;
                if (prev.length > 0) {
                    let prev_top = prev.offset().top;
                    if (prev_top - tolerance < this_top && this_top < prev_top + tolerance) {
                        first_in_line = false;
                    }
                }
                if (next.length > 0) {
                    let next_top = next.offset().top;
                    if (next_top - tolerance < this_top && this_top < next_top + tolerance) {
                        last_in_line = false
                    }
                }
                let position = "";
                if (first_in_line && last_in_line) {
                    position = "center";
                } else if (first_in_line) {
                    position = "right";
                } else {
                    position = "left";
                }
                $(this.container).attr("class", "changeable-words " + position);
                break;
        }
    }

    start(interval_ms) {
        this.interval = setInterval(this.changeWord.bind(this), interval_ms * 1000);
    }

    stop() {
        if (this.interval !== false) {
            clearInterval(this.interval);
            this.interval = false;
        }
    }

    splitWordIntoLetters(word) {
        let word_text = word.text().trim();
        word.text("");
        let letter_class = word.attr("class").replace("changeable-word", "");
        for (let i = 0; i < word_text.length; i++) {
            let char = word_text.charAt(i);
            if (char === " ") {
                char = "&nbsp;";
            }
            let letter = $(`<span class="letter ${letter_class}">${char}</span>`);
            word.append(letter);
        }
    }

    animateLetters(word, add_class, delay_ms) {
        let that = this;
        word.children(".letter").each(function(index, letter) {
            setTimeout(function() {
                $(letter).attr("class", "letter " + add_class);
            }, index * that.speed + delay_ms);
        });
    }

    animateLettersOut(word) {
        this.animateLetters(word, "out", 0);
    }

    animateLettersIn(word) {
        word.find(".letter").attr("class", "letter behind");
        word.css("opacity", 1);
        this.animateLetters(word, "in", this.delay);
    }

    changeWord() {
        let cw = this.words[this.current_word];
        this.current_word = (this.current_word + 1) % this.words.length;
        let nw = this.words[this.current_word];
        this.animateLettersOut(cw);
        this.animateLettersIn(nw);
    }
}


$(function() {
    $(".changeable-words").each(function() {
        let interval = $(this).data("interval");
        let start_delay_letters = $(this).data("startDelayLetters");
        if (start_delay_letters === undefined) {
            start_delay_letters = 0;
        }
        let align = $(this).data("align") || "auto";

        new WordChanger($(this), {
            "interval": interval,
            "startDelayLetters": start_delay_letters,
            "align": align
        });
    });
});

function PageQuickEdit() {
    this.id = 0;
    this.savedContent = "";
    this.pageAdmin = new PagesAdmin();
    this.startEditId = "";
    this.cancelEditId = "";
    this.saveEditId = "";
    this.contentId = "";

    this.editor = false;
	this.loading = false;
}

PageQuickEdit.prototype = {
	constructor: PageQuickEdit,
    saveContent: function(content) {
        this.savedContent = content;
    },
    cancelEdit: async function() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		await this.inlineEdit.cancelEdit();
		this.endEdit();
        new Notification().info("Bearbeitung abgebrochen");
    },
    startEdit: async function() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		await this.inlineEdit.startEdit();

        $("#" + this.startEditId).parent().hide();
        $("#" + this.openEditId).parent().hide();
        $("#" + this.cancelEditId).parent().show();
        $("#" + this.saveEditId).parent().show();
        $("#" + this.editCategoriesId).addClass("disabled");
		this.loading = false;
    },
    saveEdit: async function() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		if (await this.inlineEdit.saveEdit()) {
			new Notification().success("Gespeichert");
			this.endEdit();
		}
		this.loading = false;
    },
    endEdit: function() {
        $("#" + this.startEditId).parent().show();
        $("#" + this.openEditId).parent().show();
        $("#" + this.cancelEditId).parent().hide();
        $("#" + this.saveEditId).parent().hide();
        
        $("#" + this.editCategoriesId).removeClass("disabled");
		materialFullscreenInitGalleries();
		materialFullscreenInitPictures();
		this.loading = false;
    },
    editCategories: async function() {
        await this.inlineEdit.editCategories();
    },
    setup: function(id, start, cancel, save, open, categories, contentId, displaytype, pageid) {
        this.id = id;
        this.startEditId = start;
        this.cancelEditId = cancel;
        this.saveEditId = save;
        this.openEditId = open;
        this.editCategoriesId = categories;
        this.contentId = contentId;
        this.pageid = pageid;
		var admin = false;
				
		this.inlineEdit = new PostInlineEdit({id: id, container: "#" + contentId, displaytype: displaytype, wrap: displaytype != "short", pageid: pageid});

        var that = this;

        $("#" + this.startEditId).click(function() {that.startEdit();});
        $("#" + this.cancelEditId).click(function() {that.cancelEdit();});
        $("#" + this.saveEditId).click(function() {that.saveEdit();});
        $("#" + this.editCategoriesId).click(function() {that.editCategories();});
        $("#" + this.openEditId).click(function() {
			location.href = "admin/posts/edit/" + id;
			return;			
		});
		
        $("#" + this.startEditId).parent().show();
        $("#" + this.openEditId).parent().show();
        $("#" + this.editCategoriesId).parent().show();
        $("#" + this.cancelEditId).parent().hide();
        $("#" + this.saveEditId).parent().hide();
    }
};

// POST QUICKEDIT LINK
$(function() {
    $(".pageit_post_quick_edit").each(function(id, elem) {		
        $(elem).attr("id", "pageit_post_quick_edit_" + id);
        $(elem).parent().parent().find(".pageit_post_quick_cancel").attr("id", "pageit_post_quick_cancel_" + id);
        $(elem).parent().parent().find(".pageit_post_quick_save").attr("id", "pageit_post_quick_save_" + id);
        $(elem).parent().parent().find(".pageit_post_quick_open").attr("id", "pageit_post_quick_open_" + id);
        $(elem).parent().parent().find(".pageit_post_quick_categories").attr("id", "pageit_post_quick_categories_" + id);
        var post = $(elem).data("post");
        var page = $(elem).data("page") ? $(elem).data("page") : false;
        var content = $(elem).data("display");
        var displaytype = $(elem).data("displaytype") ? $(elem).data("displaytype") : "full";
        
        new PageQuickEdit().setup(post, "pageit_post_quick_edit_" + id, "pageit_post_quick_cancel_" + id, "pageit_post_quick_save_" + id, "pageit_post_quick_open_" + id, 
            "pageit_post_quick_categories_" + id, content, displaytype, page);
    });
});